import { graphql, type HeadFC, type PageProps } from 'gatsby';
import React, { type FC } from 'react';

import { FiizyShort } from '@/components/calculators/fiizy/fiizy-short';
import { ContentWithHeader } from '@/components/content/content-with-header';
import { ContentWithNavigation } from '@/components/content/content-with-navigation';
import { Html } from '@/components/content/html';
import { Shortcodes } from '@/components/content/shortcodes';
import { AppHead } from '@/components/head';
import { TableOfContents } from '@/components/navigations/table-of-contents';
import { RelatedFirms, type RelatedFirmsProps } from '@/components/related/related-firms';
import { ContactInfo, type ContactInfoProps } from '@/components/sections/firm/contact-info';
import { RatingInfo, type RatingInfoProps } from '@/components/sections/firm/rating-info';

interface FirmPageProps {
    firm: GraphqlSelect.Firm<'name' | 'descriptionHome' | 'body' | 'breadcrumbs'> & RatingInfoProps & ContactInfoProps;
    firms: { nodes: RelatedFirmsProps['render'] };
}

type FirmPageContext = GraphqlSelect.Firm<'id'>;

const FirmPage: FC<PageProps<FirmPageProps>> = ({ data: { firm, firms } }) => {
    return (
        <>
            <article>
                <ContentWithHeader
                    theme="blue"
                    breadcrumbs={firm.breadcrumbs}
                    image={<RatingInfo image={firm.image} rating={firm.rating} />}
                    style={{ marginBottom: 'clamp(18px, 5.6vw, 72px)' }}
                >
                    <h1 className="fs-56">{firm.name}</h1>

                    {firm.descriptionHome?.html && (
                        <Html style={{ marginTop: 'clamp(10px, 2.5vw, 32px)' }} className="fs-24">
                            {firm.descriptionHome.html}
                        </Html>
                    )}
                </ContentWithHeader>

                <ContentWithNavigation
                    className="container"
                    left={<Shortcodes render={firm.body.shortcodes} />}
                    right={
                        <>
                            <ContactInfo
                                name={firm.name}
                                addressName={firm.addressName}
                                addressStreet={firm.addressStreet}
                                addressNo={firm.addressNo}
                                addressCode={firm.addressCode}
                                addressCity={firm.addressCity}
                                phone={firm.phone}
                                workhours={firm.workhours}
                                email={firm.email}
                            />
                            <TableOfContents headers={firm.body.headers} />
                            <FiizyShort />
                        </>
                    }
                />
            </article>

            <section className="container mt-72">
                <h2 className="fs-36">Alte companii</h2>

                <RelatedFirms fiizy render={firms.nodes} />
            </section>
        </>
    );
};

export const Head: HeadFC<FirmPageProps, FirmPageContext> = ({
    data: { firm },
    pageContext: { id },
    location: { pathname },
}) => {
    const title = `Recenzii ${firm.name}. Informatii si sfaturi`;
    const description = `Recenzii ${firm.name}. Aici aveti toate informatiile de care aveti nevoie pentru a obtine un credit de la ${firm.name}.`;

    return (
        <AppHead index title={title} description={description} canonical={pathname} ogImage={{ id, type: 'firms' }} />
    );
};

export const query = graphql`
    fragment FirmImage on Firm {
        image {
            remoteFile {
                childImageSharp {
                    gatsbyImageData(height: 63)
                }
            }
        }
    }

    query FirmPage($id: String!, $relatedFirmsSkip: Int!) {
        firm(id: { eq: $id }) {
            ...FirmImage
            breadcrumbs
            name
            addressName
            addressStreet
            addressNo
            addressCode
            addressCity
            phone
            workhours
            email
            rating

            descriptionHome {
                html
            }

            body {
                html
                shortcodes
                headers
            }
        }

        firms: allFirm(
            limit: 4
            skip: $relatedFirmsSkip
            filter: { id: { ne: $id }, uri: { uri: { ne: null } }, visible: { eq: true } }
            sort: { priority: DESC }
        ) {
            nodes {
                ...RelatedFirms
            }
        }
    }
`;

export default FirmPage;
