import React, { type FC } from 'react';

import { FiizyButton } from '@/components/calculators/fiizy/elements/fiizy-button';

export type ContactInfoProps = GraphqlSelect.Firm<
    | 'name'
    | 'addressName'
    | 'addressStreet'
    | 'addressNo'
    | 'addressCode'
    | 'addressCity'
    | 'phone'
    | 'workhours'
    | 'email'
>;

export const ContactInfo: FC<ContactInfoProps> = ({
    name,
    addressName,
    addressStreet,
    addressNo,
    addressCode,
    addressCity,
    phone,
    workhours,
    email,
}) => {
    return (
        <div className="box bg-white-gray border-primary">
            <strong className="fs-24">Informații privind {name}</strong>

            <div
                style={{ gridTemplateColumns: 'max-content 1fr', gap: '16px 12px', marginTop: '20px' }}
                className="d-grid ai-center"
            >
                <span className="fs-14">Adresă</span>
                <b className="fs-18">
                    {addressName && addressStreet && addressCity ? (
                        <>
                            {addressName} {addressStreet}
                            {addressNo || ''}, {addressCode || ''} {addressCity}
                        </>
                    ) : (
                        '-'
                    )}
                </b>

                <span className="fs-14">Telefon</span>
                <b className="fs-18">{phone || '-'}</b>

                <span className="fs-14">Ore de lucru</span>
                <b className="fs-18">{workhours || '-'}</b>

                <span className="fs-14">Adresa de e-mail</span>
                <b className="fs-18">{email || '-'}</b>

                <span className="fs-14">Propunere</span>
                <FiizyButton />
            </div>
        </div>
    );
};
