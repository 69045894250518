import { Slice } from 'gatsby';
import React, { type FC } from 'react';

import { Html } from '@/components/content/html';

interface ShortcodesProps {
    render: (Graphql.Content.ShortcodeHtml | Graphql.Content.ShortcodeComponent)[];
}

export const Shortcodes: FC<ShortcodesProps> = ({ render }) => {
    return render.map((element, id) => {
        if ('html' in element) {
            return <Html key={id}>{element.html}</Html>;
        }

        if (element.name === 'advantages-and-disadvantages') {
            return <Slice key={id} allowEmpty alias="advantages-and-disadvantages" />;
        }

        return null;
    });
};
