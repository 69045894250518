import React, { type FC } from 'react';

type TableOfContentsProps = Graphql.Content.Headers;

export const TableOfContents: FC<TableOfContentsProps> = ({ headers }) => {
    return (
        <nav className="box bg-white-gray border-primary">
            <strong className="fs-24">Cuprins</strong>

            <ol className="list list-padding list-with-circle mt-16">
                {headers.map((header, id) => (
                    <li key={id}>
                        <a href={header.id}>{header.text}</a>
                    </li>
                ))}
            </ol>
        </nav>
    );
};
