import React, { type FC } from 'react';

export type FiveStarsProps = GraphqlSelect.Firm<'rating'>;

export const FiveStars: FC<FiveStarsProps> = ({ rating }) => {
    const style: Record<string, string> = {
        '--five-stars-width': rating * 20 + '%',
    };

    return <div className="five-stars" style={style} />;
};
