import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { type FC } from 'react';

import { FiveStars, type FiveStarsProps } from '@/components/icons/five-stars';
import { decimal } from '@/utils/format';

export type RatingInfoProps = GraphqlSelect.Firm<'image'> & FiveStarsProps;

export const RatingInfo: FC<RatingInfoProps> = ({ rating, image }) => {
    return (
        <div className="box bg-white-gray border-primary">
            {image?.remoteFile && (
                <div className="box-logotype">
                    <GatsbyImage image={getImage(image.remoteFile)!} alt="" />
                </div>
            )}

            <div style={{ gap: '7px', width: 'fit-content', margin: '12px auto 0' }} className="d-grid text-center">
                <FiveStars rating={rating} />
                <b className="fs-24">{decimal.format(rating)}</b>
            </div>
        </div>
    );
};
